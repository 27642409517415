import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from './navbar';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'react-bootstrap/Carousel';
import { getFitnessDetails } from './../store/slices/fitnesscenter';
import Travelfooter from './Travelfooter';
import Logo from '../images/logo.png';

function Traveldetails() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [plans, setPlans] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const fetchGymsData = async () => {
      try {
        const response = await axios.get(`https://api-wm.healthonify.com/get/associatedGym?showPackage=1&showQr=0&fitnessCenterId=${id}`);
        // console.log(response.data.data);
        setPlans(response?.data?.data); // Save the response data in state
      } catch (err) {
        console.log(err.message || 'Something went wrong!'); // Handle errors
      }
    };

    fetchGymsData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const ref = useRef(null);
  const dispatch = useDispatch();
  localStorage.setItem('id', id);
  const [fcName, setfcName] = useState();
  const [imageUrl, setimageUrl] = useState([]);
  const [logo, setLogo] = useState('');
  const [centeraddress, setcenterAddress] = useState('');
  const [about, setAbout] = useState('');
  const [amenities, setAmenities] = useState([]);
  const [paymentData, setPaymentData] = useState();
  const [showModal, setShowModal] = useState(false);

  React.useEffect(() => {
    dispatch(getFitnessDetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          setfcName(data.user.data[0].fcName);
          setimageUrl(data.user.data[0].mediaLink);
          setcenterAddress(data.user.data[0].fcAddress);
          setAbout(data.user.data[0].about);
          setLogo(data.user.data[0].logo);
          setAmenities(data.user.data[0].amenities);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  const handlecheckUserAndNavigate = async (plan) => {
    const user = localStorage.getItem('user');
    const parsedUser = JSON.parse(user);

    if (user) {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD
      const formattedTime = currentDate.toTimeString().split(':').slice(0, 2).join(':'); // Format: HH:MM

      let item = {
        userId: parsedUser?.data?.id,
        packageId: plan?.packageId?._id,
        startDate: formattedDate,
        startTime: formattedTime,
        flow: 'manageWeight',
      };

      try {
        // Call the initial API to create an order
        const apiResponse = await axios.post('https://api.healthonify.com/v2/subscribePackage', item);

        let rzpOrderId = apiResponse?.data?.data?.paymentData?.rzpOrderId;
        let ticketNumber = apiResponse?.data?.data?.paymentData?.ticketNumber;
        let flow = apiResponse?.data?.data?.paymentData?.flow;

        if (!rzpOrderId) {
          console.error('Order ID not received from API.');
          return;
        }

        // Initialize Razorpay with the order details
        let options = {
          key: 'rzp_live_BMjh8I1cxwWkr0',
          amount: plan?.packageId?.price * 100,
          order_id: rzpOrderId,
          name: 'Helathonify',
          description: 'GYM Package',
          image: Logo,
          handler: async function (response) {
            console.log('Razorpay response', response);
         
            // Call the payment store API after successful payment
            try {
              let paymentDetails = {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
                ticketNumber: ticketNumber,
                flow: flow,
              };
              setPaymentData(ticketNumber)
              const paymentResponse = await axios.post('https://api.healthonify.com/v2/payment/store', paymentDetails, {
                headers: { 'Content-Type': 'application/json' },
              });
              setShowModal(true);
              console.log('Payment stored successfully:', paymentResponse.data);
            } catch (paymentError) {
              console.error('Error storing payment:', paymentError.message);
            }
          },
          prefill: {
            name: parsedUser?.data?.firstName,
            email: parsedUser?.data?.email,
            contact: parsedUser?.data?.mobileNo,
          },
          notes: {
            address: 'Bangalore',
          },
          theme: {
            color: '#F37254',
          },
        };

        let rzp1 = new window.Razorpay(options);
        rzp1.open();
      } catch (err) {
        console.error(err.message || 'Something went wrong!');
      }
    } else {
      // If the user is not logged in, redirect to sign-in page
      navigate('/signin', { state: { from: window.location.pathname } });
    }
  };

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <div id="_next">
      <Header />

      <section>
        <div className="carousel_cover position-relative">
          <Carousel className="length">
            {imageUrl.map((image, index) => (
              <Carousel.Item interval={2500}>
                <img className="d-block w-100 phy_img_carousel2" src={image} alt="" />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </section>
      <section
        className="container"
        style={{
          border: 'solid 1px silver',
          borderRadius: '10px',
          padding: '20px',
        }}
      >
        <div className="d-flex justify-content-left">
          <div className="row pb-1">
            <div className="col-lg-3 col-md-3 col-sm-12 ">
              <img src={logo} alt="" />
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 ">
              <h3 className="text-left  pb-2" style={{ color: 'black' }}>
                {fcName}
              </h3>
              <p style={{ whiteSpace: 'pre-wrap' }}>
                <i className="fa fa-map-marker"></i>&nbsp;
                {centeraddress.doorNoAndStreetName}, {centeraddress.city},&nbsp;
                {centeraddress.state} {centeraddress.pincode}
              </p>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section
        className="container"
        style={{
          border: 'solid 1px silver',
          borderRadius: '10px',
          padding: '20px',
        }}
      >
        <div className="d-flex justify-content-left">
          <h3 className="text-center  pb-2" style={{ color: 'black' }}>
            About {fcName}
          </h3>
        </div>

        <div className="row pb-1">
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <p style={{ whiteSpace: 'pre-wrap' }}>{about}</p>
          </div>
        </div>
      </section>

      <section
        className="container mt-4"
        style={{
          border: 'solid 1px silver',
          borderRadius: '10px',
          padding: '20px',
        }}
      >
        <div className="d-flex justify-content-left ">
          <div className="row pb-1">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <h3 className="text-left  pb-2" style={{ color: 'black' }}>
                Amenities
              </h3>
              <p style={{ whiteSpace: 'pre-wrap' }}>
                {amenities?.map((amenity, index) => (
                  <button key={index} type="submit" className="btn btn-warning text-white" style={{ marginRight: '10px' }}>
                    {amenity.name}
                  </button>
                ))}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="container mt-4"
        style={{
          border: 'solid 1px silver',
          borderRadius: '10px',
          padding: '20px',
        }}
      >
        <h3 className="mb-4">Gym Membership Plans</h3>
        <div className="accordion" id="membershipPlansAccordion" style={{ border: 'none' }}>
          {plans?.map((plan, index) => (
            <div className="card" key={plan?.id} style={{ border: 'none' }}>
              <div
                className="card-header w-100 d-flex justify-content-between align-items-center"
                id={`heading${index}`}
                style={{ background: '#f06900', cursor: 'pointer' }}
                onClick={() => toggleAccordion(index)}
              >
                <h5 className="mb-0 d-flex justify-content-between align-items-center text-white w-100">
                  <span>{plan?.packageId?.name}</span>
                  <span className="d-flex align-items-center">
                    Starts from {plan?.packageId?.price}
                    {activeIndex === index ? <FaChevronUp className="ml-2" /> : <FaChevronDown className="ml-2" />}
                  </span>
                </h5>
              </div>
              <div id={`collapse${index}`} className={`collapse ${activeIndex === index ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-parent="#membershipPlansAccordion">
                <div className="card-body">
                  <p> {plan?.packageId?.name}</p>
                  <button className="btn btn-warning text-white" onClick={() => handlecheckUserAndNavigate(plan)}>
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {showModal && (
        <div className={`modal fade ${showModal ? 'show d-block' : ''}`} id="successModal" tabIndex="-1" aria-labelledby="successModalLabel" aria-hidden={!showModal} role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="successModalLabel">
                  Payment Successful
                </h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
              </div>
              <div className="modal-body text-center">
                <img src="https://cdn-icons-png.flaticon.com/512/190/190411.png" alt="Success" className="img-fluid mb-3" style={{ width: '100px' }} />
                <p className="mb-3">Thank you for your payment!</p>
                <p>
                  <strong>Your Ticket Number:</strong>
                  <span className="fw-bold text-primary"> {paymentData || 'N/A'}</span>
                </p>
                <p>
                  You can check more details on the Healthonify app in the
                  <strong> "My Subscription"</strong> section.
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-success" onClick={onClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <br />
      <br />
      <Travelfooter />
    </div>
  );
}

export default Traveldetails;
